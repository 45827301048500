import React, { ReactNode } from 'react';

export const Background = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  return (
    <div className="flex flex-col items-stretch min-h-screen bg-gray-200 dark:bg-black transition-all">
      {children}
    </div>
  );
};
