import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import './app.css';

const gaTrackingId = 'G-JVHL1BL9SG';

type PlausibleArguments = unknown[];

// Extending window type for plausible
declare global {
  interface Window {
    plausible?: (...args: PlausibleArguments) => void;
    q?: PlausibleArguments;
  }
}

const fetchDomain = (): string => {
  if (window.location.port.length > 0) {
    return window.location.hostname;
  }

  const domain = window.location.hostname.match(
    /^(?:.*?\.)?(\w{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/
  );

  return (Array.isArray(domain) ? domain[1] : domain) as string;
};

const App = (): JSX.Element => {
  const currentDomain = fetchDomain();

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize(gaTrackingId, {
      gaOptions: {
        allowLinker: true,
      },
    });
    ReactGA.send({ hitType: 'pageview', page: '/' });
    ReactGA.event(`Accessed ${currentDomain}`);

    // Initialize Plausible if it's not already
    window.plausible =
      window.plausible ||
      function (...args: PlausibleArguments): void {
        (window.q = window.q || []).push(...args);
      };
  }, [currentDomain]);

  return (
    <div className="text-3xl md:text-5xl lg:text-7xl 2xl:text-9xl content">
      <Helmet>
        <title>{currentDomain}</title>
        {/* Injecting the Plausible script dynamically */}
        <script
          async
          defer
          data-domain={currentDomain}
          data-api="https://analytics.mv/api/pulse"
          src="https://analytics.mv/js/engine.js"
        />
      </Helmet>

      <h2>{currentDomain}</h2>
      <h2>{currentDomain}</h2>
    </div>
  );
};

export default App;
