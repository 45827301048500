import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app';
import reportWebVitals from './report-web-vitals';
import { ThemeProvider } from './components/theme-context';
import { Background } from './components/background';
import Toggle from './components/theme-toggle';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <Background>
        <div className="flex-shrink-0">
          <div className="absolute top-0 right-0 mt-4 mr-4 md:mt-6 md:mr-6">
            <Toggle />
          </div>
        </div>
        <div className="flex flex-col flex-1 justify-center items-center text-center">
          <App />
        </div>
        <div className="flex-shrink-0 mb-5 text-xs text-center text-gray-700 dark:text-gray-300">
          <a target="_blank" href="https://weare.mv" rel="noopener noreferrer">
            with ❤️ &nbsp;Dot Maldives
          </a>
        </div>
      </Background>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
